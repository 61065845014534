.header {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-light);

    .precooked-nav {
        a.active {
            font-weight: 600;
        }
    }
}